import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import {Dialog, DialogContent, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, styled, Paper, AppBar, Tabs, Tab, Box, Typography, Tooltip } from "@mui/material";
import Message from '../modal/message';
import Payment from '../modal/payment';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { AbonentContext } from './content';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && (
                                    <Box p={3}>
                                        <Typography>{children}</Typography>
                                    </Box>
                                    )}
            </div>
            );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

function Lk(props) {
    const [rows, setRows] = useState([])
    const [prize, setPrize] = useState([])
    const [checkCount, setCheckCount] = useState({})
    const [prizeRaffle, setPrizeRaffle] = useState({
        scooter: false,
        balanceBoard: false,
        bmx: false,
        snowboard: false,
        rollers: false,
        prize: '',
        activePrize: false
    })
    const {abonent, setAbonent, clickId, trackId, setClickId, setTrackId, language} = useContext(AbonentContext)
    const [value, setValue] = useState(0);
    const [showModalMessage, setShowModalMessage] = useState(false)
    const [modalMessageText, setModalMessageText] = useState("")
    const [modalMessageType, setModalMessageType] = useState(1)
    const [open, setOpen] = useState({});
    const [showModalPayment, setShowModalPayment] = useState(false)
    const [prizePayment, setPrizePayment] = useState([])
    const idClickButtonCheck = useRef('')
    const mobile = window.innerWidth <= 1023;

    useEffect(() => {
        fetch(props.urlServer + "/json/get/checksAbonent", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({programType: null}),
            headers: {
                "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                "Content-Type": "application/json"
            }
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setRows(resp)
            setOpen((open) => Array(resp.length).fill().map((_, i) => open[i] = false))
        }, (error) => {

        })
    }, [])

    useEffect(() => {
        fetch(props.urlServer + "/json/get/checksAbonentCount", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({}),
            headers: {
                "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                "Content-Type": "application/json"
            }
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setCheckCount(resp)
        }, (error) => {

        })
    }, [])

    useEffect(() => {
        fetch(props.urlServer + "/json/get/prizeAbonent", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({}),
            headers: {
                "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                "Content-Type": "application/json"
            }
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setPrize(resp)
//            setPrizePayment(resp.filter(item => item.name == '30 руб. на телефон'))
        }, (error) => {

        })
    }, [showModalPayment])

    if (abonent.authorized === false) {
        window.location.replace("/")
    }

//    console.log(clickId +"\n"+ trackId);

    function handleCloseModalMessage() {
        if (modalMessageType === 1) {
            setModalMessageText("")
            setShowModalMessage(false)

//            if (prizePayment[0]) {
//                if (prizePayment[0].status == 'Подтверждение данных') {
//                    setModalMessageType(-1)
//                    setShowModalMessage(true)
//                    setModalMessageText('Не забудь пополнить баланс телефона! Тебе начислено 30 рублей! Кликни на приз и следуй инструкции.')
//                }
//            }
        } else if (modalMessageType === -1) {
            setModalMessageText("")
            setShowModalMessage(false)
        }
    }

    function openCheck() {
        setModalMessageType(1)
        setModalMessageText(language === 'Рус' ? '<h1>АКЦИЯ ЗАВЕРШЕНА</h1>\n\
                                     \n\
                                     Уважаемые участники!\n\
                                     Регистрация в акции завершена 31 октября 2023 г.'
                                     :
                                     '<h1>АКЦИЯ АЯҚТАЛДЫ</h1>\n\
                                      Құрметті қатысушылар!\n\
                                      Науқанға тіркелу 2023 жылдың 31 қазанында аяқталды.'
                                    )
        setShowModalMessage(true)
    }

    function exitLk() {
        fetch(props.urlServer + "/json/form/logout.json", {
            method: "POST",
            credentials: "include",
            headers: {
                "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1")
            }
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            window.location.replace("/")
        }, (error) => {
            console.log(error);
        })
    }

    const handleTooltipClose = (event) => {
        const id = event.target.id;
//        console.log(id);
        setOpen({...open, [id]: false});
//        console.log(open);
    };

    const handleTooltipOpen = (event) => {
        const id = event.target.id;
//        console.log(id);
        setOpen({...open, [id]: true});
//        console.log(open);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const check = rows.map((row, index) => {
        if (!mobile) {
            return(
                    <TableRow key={index}>
                        <TableCell align="left" dangerouslySetInnerHTML={{__html: index++ < 9 ? '0' + index++ : index++}}></TableCell>
                        <TableCell align="left" dangerouslySetInnerHTML={{__html: row.number ? row.number : ""}}></TableCell>
                        <TableCell align="left" dangerouslySetInnerHTML={{__html: row.date ? Moment(row.date).format('DD.MM.YYYY') : ""}}></TableCell>
                        {language === 'Рус' ?
                                    (!row.comment && <TableCell align="left" dangerouslySetInnerHTML={{__html: row.name ? row.name : ""}}></TableCell>)
                                    :
                                    (!row.comment_kz && <TableCell align="left" dangerouslySetInnerHTML={{__html: row.name_kz ? row.name_kz : ""}}></TableCell>)
                        }
                        {language === 'Рус' ?
                                    (row.comment && <TableCell align="left" >{row.name} 
                                        {!mobile ?
                                                                                (<Tooltip
                                                                                    title={row.comment} 
                                                                                    placement="top-start">
                                                                                    <img src="/images/lk-check-status-coment.png" id={index}/>
                                                                                </Tooltip>)
                                                                                :
                                                                                (<Tooltip
                                                                                    disableTouchListener
                                                                                    onClose={handleTooltipClose}
                                                                                    open={open[index]}
                                                                                    title={row.comment} 
                                                                                    placement="top-start">
                                                                                    <img src="/images/lk-check-status-coment.png" id={index} onClick={handleTooltipOpen}/>
                                                                                </Tooltip>)
                                        }
                                    </TableCell>)
                                    :
                                    (row.comment_kz && <TableCell align="left" >{row.name_kz} 
                                        {!mobile ?
                                                                                (<Tooltip
                                                                                    title={row.comment_kz} 
                                                                                    placement="top-start">
                                                                                    <img src="/images/lk-check-status-coment.png" id={index}/>
                                                                                </Tooltip>)
                                                                                :
                                                                                (<Tooltip
                                                                                    disableTouchListener
                                                                                    onClose={handleTooltipClose}
                                                                                    open={open[index]}
                                                                                    title={row.comment_kz} 
                                                                                    placement="top-start">
                                                                                    <img src="/images/lk-check-status-coment.png" id={index} onClick={handleTooltipOpen}/>
                                                                                </Tooltip>)
                                        }
                                    </TableCell>)
                        }
                    </TableRow>
                    )
        } else {
            return(
                    <TableRow key={index}>
                        <TableCell><p dangerouslySetInnerHTML={{__html: row.number ? row.number : ""}}></p><p dangerouslySetInnerHTML={{__html: row.date ? Moment(row.date).format('DD.MM.YYYY') : ""}}></p></TableCell>
                        {language == 'Рус' ?
                                    (!row.comment && <TableCell align="left" dangerouslySetInnerHTML={{__html: row.name ? row.name : ""}}></TableCell>)
                                    :
                                    (!row.comment && <TableCell align="left" dangerouslySetInnerHTML={{__html: row.name_kz ? row.name_kz : ""}}></TableCell>)
                        }
                        {language == 'Рус' ?
                                    (row.comment &&
                                                    <TableCell align="left" >{row.name} 
                                                        {!mobile ?
                                                                        (<Tooltip
                                                                            title={row.comment} 
                                                                            placement="top-start">
                                                                            <img src="/images/lk-check-status-coment.png" id={index}/>
                                                                        </Tooltip>)
                                                                        :
                                                                        (<Tooltip
                                                                            disableTouchListener
                                                                            onClose={handleTooltipClose}
                                                                            open={open[index]}
                                                                            title={row.comment} 
                                                                            placement="top-start">
                                                                            <img src="/images/lk-check-status-coment.png" id={index} onClick={handleTooltipOpen}/>
                                                                        </Tooltip>)
                                                        }
                                                    </TableCell>)
                                    :
                                    (row.comment_kz &&
                                                    <TableCell align="left" >{row.name_kz} 
                                                        {!mobile ?
                                                                        (<Tooltip
                                                                            title={row.comment_kz} 
                                                                            placement="top-start">
                                                                            <img src="/images/lk-check-status-coment.png" id={index}/>
                                                                        </Tooltip>)
                                                                        :
                                                                        (<Tooltip
                                                                            disableTouchListener
                                                                            onClose={handleTooltipClose}
                                                                            open={open[index]}
                                                                            title={row.comment_kz} 
                                                                            placement="top-start">
                                                                            <img src="/images/lk-check-status-coment.png" id={index} onClick={handleTooltipOpen}/>
                                                                        </Tooltip>)
                                                        }
                                                    </TableCell>)
                        }     
                    </TableRow>
                    )
        }
    });

    const priz = prize.map((row, index) => {
        if (!mobile) {
            return(
                    <TableRow key={index}>
                        <TableCell align="left" dangerouslySetInnerHTML={{__html: index++ < 9 ? '0' + index++ : index++}}></TableCell>
                        {language === 'Рус' ?
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.name ? row.name : ""}}></TableCell>)
                            :
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.name_kz ? row.name_kz : ""}}></TableCell>)
                            }
                        {language === 'Рус' ?
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.caption ? row.caption : ""}}></TableCell>)
                            :
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.caption_kz ? row.caption_kz : ""}}></TableCell>)
                        }
                        {language === 'Рус' ?
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.status ? row.status : ""}}></TableCell>)
                            :
                            (<TableCell align="left" dangerouslySetInnerHTML={{__html: row.status_kz ? row.status_kz : ""}}></TableCell>)
                        }
                    </TableRow>
                    )
        } else {
            return(
                    <TableRow key={index}>
                        {language === 'Рус' ?
                            (<TableCell>
                                <b><p dangerouslySetInnerHTML={{__html: row.caption ? row.caption : ""}}></p></b>
                                <p dangerouslySetInnerHTML={{__html: row.name ? row.name : ""}}></p>
                            </TableCell>)
                            :
                            (<TableCell>
                                <b><p dangerouslySetInnerHTML={{__html: row.caption_kz ? row.caption_kz : ""}}></p></b>
                                <p dangerouslySetInnerHTML={{__html: row.name_kz ? row.name_kz : ""}}></p>
                            </TableCell>)
                        }
                        {language === 'Рус' ?
                            (<TableCell dangerouslySetInnerHTML={{__html: row.status ? row.status : ""}}></TableCell>)
                            :
                            (<TableCell dangerouslySetInnerHTML={{__html: row.status_kz ? row.status_kz : ""}}></TableCell>)
                        }
                    </TableRow>
                    )
        }
    })

    return(
            <>
            <div className="block-lk">
                <div className="block-lk">
                    <div className="block-lk-content">
                        <div className="block-h1">
                            <h1>{language === 'Рус' ? 'Личный кабинет' : 'Жеке кабинет'}</h1>
                        </div>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label={language === 'Рус' ? 'Профайл' : 'Профайл'} {...a11yProps(0)} />
                                <Tab label={language === 'Рус' ? 'Чеки' : 'Чектер'} {...a11yProps(1)} />
                                <Tab label={language === 'Рус' ? 'Призы' : 'Жүлделер'} {...a11yProps(2)} />
                                <Tab label={language === 'Рус' ? 'Выход' : 'Шығу'} {...a11yProps(3)} onClick={exitLk}/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <div className="lk-block-data-user">
                                <div className="lk-data-user">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="">
                                            <TableBody>
                                                <TableRow key="">
                                                    <TableCell dangerouslySetInnerHTML={{__html: abonent.i ? abonent.i : ''}}></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell dangerouslySetInnerHTML={{__html: abonent.phone ? abonent.phone : ''}}></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="lk-data-check">
                                    <div className="lk-data-check-block">
                                        <p>{language === 'Рус' ? 'Зарегистрировано чеков' : 'Тіркелген чектер'}</p>
                                    </div>
                                    <div className="lk-data-check-number">{checkCount.countChecks}</div>
                                    <Button className="lk-data-check-button" id="check_cabinet" onClick={openCheck}>{language === 'Рус' ? 'Зарегистрировать чек' : 'Чекті тіркеу'}</Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="lk-block-check">
                                <div className="lk-check-table">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="">
                                            <TableHead>
                                                {!mobile &&
                                                    <TableRow>
                                                        <TableCell >№</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Чек' : 'Чек'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Дата регистрации' : 'Тіркелген күні'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Статус' : 'Мәртебесі'}</TableCell>
                                                    </TableRow>
                                                }
                                                {mobile &&
                                                    <TableRow>
                                                        <TableCell >{language === 'Рус' ? 'Чек / Дата' : 'Чек / Күні'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Статус' : 'Мәртебесі'}</TableCell>
                                                    </TableRow>
                                                }
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                    <Scrollbars style={{width: '78.125vw', height: '18.40278vw'}} renderView={props => <div {...props} className="view"/>}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="">
                                                <TableBody>
                                                    {check}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Scrollbars>
                                </div>
                                <Button className="lk-data-check-button" id="check_cabinet" onClick={openCheck}>{language === 'Рус' ? 'Зарегистрировать чек' : 'Чекті тіркеу'}</Button>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className="lk-block-prize">
                                <div className="lk-prize-table">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="">
                                            <TableHead>
                                                {!mobile &&
                                                    <TableRow>
                                                        <TableCell >№</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Вид приза' : 'Жүлде түрі'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Приз' : 'Жүлде'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? 'Статус' : 'Мәртебе'}</TableCell>
                                                    </TableRow>
                                                }
                                                {mobile &&
                                                    <TableRow>
                                                        <TableCell >{language === 'Рус' ? 'Приз' : 'Жүлде'}</TableCell>
                                                        <TableCell >{language === 'Рус' ? '' : ''}</TableCell>
                                                    </TableRow>
                                                }
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                    <Scrollbars style={{width: '78.125vw', height: '13.1945vw'}} renderView={props => <div {...props} className="view"/>}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="">
                                                <TableBody>
                                                    {priz}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Scrollbars>
                                </div>
                                <Button className="lk-data-check-button" id="check_cabinet" onClick={openCheck}>{language === 'Рус' ? 'Зарегистрировать чек' : 'Чекті тіркеу'}</Button>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>
            <Message showModalMessage={showModalMessage} modalMessageText={modalMessageText} handleCloseModalMessage={handleCloseModalMessage}/>
            </>
            )
}

export default Lk