import React from 'react';

const Mixer2 = () => {
    return (
            <div id="animation_container_2">
                <canvas id="canvas_2"></canvas>
                <div id="dom_overlay_container_2"></div>
            </div>
            )
}

export default Mixer2