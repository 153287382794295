import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import {Dialog, DialogContent, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, styled, Paper, AppBar, Tabs, Tab, Box, Typography, Tooltip, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import Moment from 'moment';
import InputMask from "react-input-mask";
import ReactPlayer from 'react-player';
import { AbonentContext } from './content';
import Mixer from './mixer';
import Mixer2 from './mixer2';
import SwiperProduct from '../swiper/swiperShow';
import curdCheeseData from '../../images/curdCheese.json';


function Index(props) {
    const [dateProduct, setDateProduct] = useState([
        {'name': 'condensed-milk', 'active': false},
        {'name': 'palermo', 'active': false},
        {'name': 'cottage-cheese', 'active': false},
        {'name': 'figs', 'active': false},
        {'name': 'butter', 'active': false},
        {'name': 'vanilla', 'active': false},
        {'name': 'coconut', 'active': false},
        {'name': 'toffee', 'active': false}
    ]);
    const [dateEmotions, setDateEmotions] = useState([
        {'name': 'inspiration', 'active': false, 'caption': 'вдохновением'},
        {'name': 'pleasure', 'active': false, 'caption': 'удовольствием'},
        {'name': 'pride', 'active': false, 'caption': 'гордостью'},
        {'name': 'cheerfulness', 'active': false, 'caption': 'бодростью'},
        {'name': 'joy', 'active': false, 'caption': 'радостью'},
        {'name': 'fun', 'active': false, 'caption': 'весельем'},
        {'name': 'harmony', 'active': false, 'caption': 'гармонией'},
        {'name': 'astonishment', 'active': false, 'caption': 'удивлением'}
    ]);
    const resultCurdCheese = useRef([]);
    const [resultEmotions, setResultEmotions] = useState([]);
    const [result, setResult] = useState();
    const isMobile = window.innerWidth <= 1023;

//    function restartGIF(id) {
//        const image = new Image();
//        const url = id == 'gifProduct' ? 'images/block3_product_center_animation.gif' : 'images/block4_emotions_center_animation.gif';
//        image.src = url;
//        $('#' + id).attr('src', image.src);
//    }

    function mixActiveHide() {
        if ($('.block3').hasClass('hide-block3')) {
            $('.block5').removeClass('active-block5');
            $('.step-active').removeClass('step-active');
            $('.inspiration-2').removeClass('inspiration-2-active');
            $('.pleasure-2').removeClass('pleasure-2-active');
            $('.pride-2').removeClass('pride-2-active');
            $('.cheerfulness-2').removeClass('cheerfulness-2-active');
            $('.joy-2').removeClass('joy-2-active');
            $('.fun-2').removeClass('fun-2-active');
            $('.harmony-2').removeClass('harmony-2-active');
            $('.astonishment-2').removeClass('astonishment-2-active');
            setDateEmotions([
                {'name': 'inspiration', 'active': false, 'caption': 'вдохновением'},
                {'name': 'pleasure', 'active': false, 'caption': 'удовольствием'},
                {'name': 'pride', 'active': false, 'caption': 'гордостью'},
                {'name': 'cheerfulness', 'active': false, 'caption': 'бодростью'},
                {'name': 'joy', 'active': false, 'caption': 'радостью'},
                {'name': 'fun', 'active': false, 'caption': 'весельем'},
                {'name': 'harmony', 'active': false, 'caption': 'гармонией'},
                {'name': 'astonishment', 'active': false, 'caption': 'удивлением'}
            ]);
            setResultEmotions([]);
            $('.emotions-step-center').removeClass('emotions-step-center-hide');
            $('.emotions-step-center-animation').removeClass('emotions-step-center-active');
//            const image = new Image();
//            image.src = 'images/block3_product_center.png';
//            $('.emotions-step-center>img').attr('src', image.src);
            $('#buttonEmotions').removeClass('button-active');
            iniMixer2Stop();
            $('.block4').removeClass('hide-block4');
            $('.block4').removeClass('active-block4');
            $('.condensed-milk-2').removeClass('condensed-milk-2-active');
            $('.palermo-2').removeClass('palermo-2-active');
            $('.cottage-cheese-2').removeClass('cottage-cheese-2-active');
            $('.figs-2').removeClass('figs-2-active');
            $('.butter-2').removeClass('butter-2-active');
            $('.vanilla-2').removeClass('vanilla-2-active');
            $('.coconut-2').removeClass('coconut-2-active');
            $('.toffee-2').removeClass('toffee-2-active');
            setDateProduct([
                {'name': 'condensed-milk', 'active': false},
                {'name': 'palermo', 'active': false},
                {'name': 'cottage-cheese', 'active': false},
                {'name': 'figs', 'active': false},
                {'name': 'butter', 'active': false},
                {'name': 'vanilla', 'active': false},
                {'name': 'coconut', 'active': false},
                {'name': 'toffee', 'active': false}
            ]);
            $('.product-step-center').removeClass('product-step-center-hide');
            $('.product-step-center-animation').removeClass('product-step-center-active');
//            const img = new Image();
//            img.src = 'images/block3_product_center.png';
//            $('.product-step-center>img').attr('src', img.src);
            $('#buttonProduct').removeClass('button-active');
            iniMixerStop();
            $('.block3').removeClass('hide-block3');
            if (!isMobile) {
                location.href = "#Mix_product";
            }
        } else {
            location.href = "#Mix_product";
        }
    }

    function selectProduct(event) {
        const name = event.currentTarget.id;
        if (dateProduct.filter(item => item.active == true).length < 3 && !$('.' + name).hasClass('step-active')) {
            $('.' + name).addClass('step-active');
            $('.' + name + '-2').addClass(name + '-2-active');
        } else {
            $('.' + name).removeClass('step-active');
            $('.' + name + '-2').removeClass(name + '-2-active');
        }
        $('#buttonProduct').addClass('button-active');
        const product = dateProduct.map((value, id) => {
            if (value.name == name) {
                return {'name': name, 'active': $('.' + name).hasClass('step-active')};
            } else {
                return value;
            }
        });
        setDateProduct(product);
    }

    function selectEmotions(event) {
        const name = event.currentTarget.id;
        if (dateEmotions.filter(item => item.active == true).length < 2 && !$('.' + name).hasClass('step-active')) {
            $('.' + name).addClass('step-active');
            $('.' + name + '-2').addClass(name + '-2-active');
        } else {
            $('.' + name).removeClass('step-active');
            $('.' + name + '-2').removeClass(name + '-2-active');
        }
        $('#buttonEmotions').addClass('button-active');
        const emotions = dateEmotions.map((value, id) => {
            if (value.name == name) {
                return {'name': value.name, 'active': $('.' + name).hasClass('step-active'), 'caption': value.caption};
            } else {
                return value;
            }
        });
        setDateEmotions(emotions);
        setResultEmotions(emotions.filter(item => item.active == true));
    }

    function mixEmotions() {
        const productActive = dateProduct.filter(item => item.active == true);
        if (productActive.length > 0 && $('#buttonProduct').hasClass('button-active')) {
            if (!isMobile) {
                location.href = "#productMixer";
            }
            $('#buttonProduct').removeClass('button-active');
//        restartGIF('gifProduct');
//            const image = new Image();
//            image.src = 'images/block3_product_center_animation_new.gif' + "?a=" + Math.random();
//            $('.product-step-center-animation>img').attr('src', image.src);

            setTimeout(function () {
                iniMixerPlay();
            }, 2500);
            
//            setTimeout(function () {
//                $('.product-step-center').addClass('product-step-center-hide');
//                $('.product-step-center-animation').addClass('product-step-center-active');
//            }, 3000);

            setTimeout(function () {
                $('.block3').addClass('hide-block3');
                $('.block4').addClass('active-block4');
                if (!isMobile) {
                    location.href = "#Mix_emotions";
                }
            }, 4750);
        }
    }

    function mixs() {
        const productActive = dateProduct.filter(item => item.active == true);
        if (productActive.length == 1) {
            let map1 = curdCheeseData.filter(item => item.product.length == 1);
            map1.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[0].name) {
                        resultCurdCheese.current = [value];
                    }
                });
            });
        }
        if (productActive.length == 2) {
            let map2 = curdCheeseData.filter(item => item.product.length == 2);
            let map3 = [];
            map2.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[0].name) {
                        map3.push(value);
                    }
                });
            });
            map3.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[1].name) {
                        resultCurdCheese.current = [value];
                    }
                });
            });
        }
        if (productActive.length == 3) {
            let map4 = curdCheeseData.filter(item => item.product.length == 3);
            let map5 = [];
            map4.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[0].name) {
                        map5.push(value);
                    }
                });
            });
            let map6 = [];
            map5.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[1].name) {
                        map6.push(value);
                    }
                });
            });
            map6.map((value, id) => {
                value.product.map((val, i) => {
                    if (val.name == productActive[2].name) {
                        resultCurdCheese.current = [value];
                    }
                });
            });
        }
        if (resultEmotions.length > 0 && $('#buttonEmotions').hasClass('button-active')) {
            if (!isMobile) {
                location.href = "#emotionsMixer";
            }
            $('#buttonEmotions').removeClass('button-active');
//            restartGIF('gifEmotions');
//            const img = new Image();
//            img.src = 'images/block3_product_center_animation_new.gif' + "?a=" + Math.random();
//            $('.emotions-step-center-animation>img').attr('src', img.src);

            setTimeout(function () {
                iniMixer2Play();
            }, 2500);
            
//            setTimeout(function () {
//                $('.emotions-step-center').addClass('emotions-step-center-hide');
//                $('.emotions-step-center-animation').addClass('emotions-step-center-active');
//            }, 3000);
            
            setTimeout(function () {
                const resEmotions = resultEmotions.map((value, id) => {
                    let str = '';
                    if (id == 0) {
                        str = value.caption;
                        return (
                                <>{value.caption}</>
                                )
                    } else {
                        return (
                                <>{str + ' и ' + value.caption}</>
                                )
                    }
                });
                setResult(resultCurdCheese.current.map((value, id) => {
//                    $('.block5').css('background', 'url(images/' + value.img + ')');
                    return (
                            <>
                            <img src={'images/' + value.img}/>
                            <h1>Поздравляем!</h1>
                            <p>{value.text}</p>
                            <p>Замиксованный вами сырок уже готов наполнить  ваш день {resEmotions} ! А чтобы это точно произошло, вам остается только нажать на кнопку ниже</p>
                            <a href={value.link}><p>КУПИТЬ</p></a>
                            </>
                            )
                }));
                $('.block4').addClass('hide-block4');
                $('.block5').addClass('active-block5');
                if (!isMobile) {
                    location.href = "#Mix_result";
                }
            }, 4750);
        }
    }

    return (
            <>
            <div className="block-content">
                <div className="block1">
                    <div className="wrapper1420">
                        <p>
                            Каждый день — это микс из разнообразных событий, встреч, новостей, переписок и дел. Мы знаем, как в этой круговерти забот создать классное настроение! Для этого у нас есть целая линейка творожных сырков «Свитлогорье», каждый из которых способен украсить ваш день.  
                            <br/>
                            <br/>Проведите свой перерыв со вкусом! А с каким именно — поможет наш волшебный миксатор.
                        </p>
                        <div className="block-button">
                            <p>Сырок дня для меня</p>
                            <Button onClick={mixActiveHide}><p>Замиксовать</p></Button>
                        </div>
                    </div>
                </div>
                <div className="block2">
                    <div className="wrapper1420">
                        <div className="block2-content">
                            <h1>СОЗДАЙТЕ <span>СВОЙ</span> ТВОРОЖНЫЙ СЫРОК</h1>
                            <p>
                                От творожного глазированного сырка с любимым вкусом не откажется никто. Но как узнать, какой именно вкус полностью подходит именно вам?
                                <br/>
                                <br/>У нас есть ответ: замиксуйте творожный сырок самостоятельно! Выбирайте ингредиенты и текстуры, которые нравятся вам больше всего, соединяйте их — и узнайте, какой именно творожный сырок «Свитлогорье» подходит именно вам.
                            </p>
                            <Button onClick={mixActiveHide}><p>Замиксовать</p></Button>
                        </div>
                    </div>
                </div>
                <div className="block3">
                    <div className="wrapper1420">
                        {isMobile ?
                                    (<>
                                    <p id="Mix_product">
                                        Творожные сырки “Свитлогорье” — это разнообразное сочетание  
                                        великолепных ингредиентов, которое не только рождает незабываемый вкус,  
                                        но и дарит приподнятое настроение. Всегда — разное!
                                    </p>
                                    <h1>
                                        Выберите то, что нравится вам больше всего  
                                        <br/>в данный момент, и переместите в миксатор.
                                    </h1>
                                    </>)
                                    :
                                    (<>
                                    <p id="Mix_product">
                                        Творожные сырки “Свитлогорье” — это разнообразное сочетание  
                                        <br/>великолепных ингредиентов, которое не только рождает незабываемый вкус,  
                                        <br/>но и дарит приподнятое настроение. Всегда — разное!
                                    </p>
                                    <h1>
                                        Выберите то, что нравится вам больше всего  
                                        <br/>в данный момент, и переместите в миксатор.
                                    </h1>
                                    </>)
                        }
                        <div className="product" id="productMixer">
                            <div className="product-step condensed-milk" id="condensed-milk" value="false" onClick={selectProduct}><img src="images/block3_product_condensed_milk.png"/><p>сгущёнка</p></div>
                            <div className="product-step condensed-milk-2" id="condensed-milk" onClick={selectProduct}><img src="images/block3_product_condensed_milk.png"/><p>сгущёнка</p></div>
                            <div className="product-step palermo" id="palermo" onClick={selectProduct}><img src="images/block3_product_palermo.png"/><p>сыр palermo</p></div>
                            <div className="product-step palermo-2" id="palermo" onClick={selectProduct}><img src="images/block3_product_palermo.png"/><p>сыр palermo</p></div>
                            <div className="product-step cottage-cheese" id="cottage-cheese" onClick={selectProduct}><img src="images/block3_product_cottage_cheese.png"/><p>творог</p></div>
                            <div className="product-step cottage-cheese-2" id="cottage-cheese" onClick={selectProduct}><img src="images/block3_product_cottage_cheese.png"/><p>творог</p></div>
                            <div className="product-step figs" id="figs" onClick={selectProduct}><img src="images/block3_product_figs.png"/><p>инжир</p></div>
                            <div className="product-step figs-2" id="figs" onClick={selectProduct}><img src="images/block3_product_figs.png"/><p>инжир</p></div>
                            <div className="product-step butter" id="butter" onClick={selectProduct}><img src="images/block3_product_butter.png"/><p>сливочное  <br/>масло</p></div>
                            <div className="product-step butter-2" id="butter" onClick={selectProduct}><img src="images/block3_product_butter.png"/><p>сливочное  <br/>масло</p></div>
                            <div className="product-step vanilla" id="vanilla" onClick={selectProduct}><img src="images/block3_product_vanilla.png"/><p>ваниль</p></div>
                            <div className="product-step vanilla-2" id="vanilla" onClick={selectProduct}><img src="images/block3_product_vanilla.png"/><p>ваниль</p></div>
                            <div className="product-step coconut" id="coconut" onClick={selectProduct}><img src="images/block3_product_coconut.png"/><p>кокос</p></div>
                            <div className="product-step coconut-2" id="coconut" onClick={selectProduct}><img src="images/block3_product_coconut.png"/><p>кокос</p></div>
                            <div className="product-step toffee" id="toffee" onClick={selectProduct}><img src="images/block3_product_toffee.png"/><p>ириски</p></div>
                            <div className="product-step toffee-2" id="toffee" onClick={selectProduct}><img src="images/block3_product_toffee.png"/><p>ириски</p></div>
                            <div className="product-step-center"><Mixer /></div>
                        </div>
                        <Button onClick={mixEmotions} id="buttonProduct"><p>Замиксовать</p></Button>
                    </div>
                </div>
                <div className="block4" id="">
                    <div className="wrapper1420">
                        {isMobile ?
                                    (<>
                                    <h1 id="Mix_emotions">
                                        А теперь давайте создадим вам настроение.
                                        Выберите, чем бы вам хотелось украсить сегодняшний день,  
                                        и переместите в миксатор.
                                    </h1>
                                    </>)
                                    :
                                    (<>
                                    <h1 id="Mix_emotions">
                                        А теперь давайте создадим вам настроение.
                                        <br/>Выберите, чем бы вам хотелось украсить сегодняшний день,  
                                        <br/>и переместите в миксатор.
                                    </h1>
                                    </>)
                        }
            
                        <div className="emotions" id="emotionsMixer">
                            <div className="emotions-step inspiration" id="inspiration" onClick={selectEmotions}><img src="images/block4_emotions_inspiration.gif"/><p>вдохновение</p></div>
                            <div className="emotions-step inspiration-2" id="inspiration" onClick={selectEmotions}><img src="images/block4_emotions_inspiration.gif"/><p>вдохновение</p></div>
                            <div className="emotions-step pleasure" id="pleasure" onClick={selectEmotions}><img src="images/block4_emotions_pleasure.gif"/><p>удовольствие</p></div>
                            <div className="emotions-step pleasure-2" id="pleasure" onClick={selectEmotions}><img src="images/block4_emotions_pleasure.gif"/><p>удовольствие</p></div>
                            <div className="emotions-step pride" id="pride" onClick={selectEmotions}><img src="images/block4_emotions_pride.gif"/><p>Гордость</p></div>
                            <div className="emotions-step pride-2" id="pride" onClick={selectEmotions}><img src="images/block4_emotions_pride.gif"/><p>Гордость</p></div>
                            <div className="emotions-step cheerfulness" id="cheerfulness" onClick={selectEmotions}><img src="images/block4_emotions_cheerfulness.gif"/><p>Бодрость</p></div>
                            <div className="emotions-step cheerfulness-2" id="cheerfulness" onClick={selectEmotions}><img src="images/block4_emotions_cheerfulness.gif"/><p>Бодрость</p></div>
                            <div className="emotions-step joy" id="joy" onClick={selectEmotions}><img src="images/block4_emotions_joy.gif"/><p>радость</p></div>
                            <div className="emotions-step joy-2" id="joy" onClick={selectEmotions}><img src="images/block4_emotions_joy.gif"/><p>радость</p></div>
                            <div className="emotions-step fun" id="fun" onClick={selectEmotions}><img src="images/block4_emotions_fun.gif"/><p>веселье</p></div>
                            <div className="emotions-step fun-2" id="fun" onClick={selectEmotions}><img src="images/block4_emotions_fun.gif"/><p>веселье</p></div>
                            <div className="emotions-step harmony" id="harmony" onClick={selectEmotions}><img src="images/block4_emotions_harmony.gif"/><p>Гармония</p></div>
                            <div className="emotions-step harmony-2" id="harmony" onClick={selectEmotions}><img src="images/block4_emotions_harmony.gif"/><p>Гармония</p></div>
                            <div className="emotions-step astonishment" id="astonishment" onClick={selectEmotions}><img src="images/block4_emotions_astonishment.gif"/><p>удивление</p></div>
                            <div className="emotions-step astonishment-2" id="astonishment" onClick={selectEmotions}><img src="images/block4_emotions_astonishment.gif"/><p>удивление</p></div>
                            <div className="emotions-step-center"><Mixer2 /></div>
                        </div>
                        <Button onClick={mixs} id="buttonEmotions"><p>Замиксовать</p></Button>
                    </div>
                </div>
                <div className="block5" id="Mix_result">
                    {result}
                </div>
                <div className="block6" id="Brend">
                    <div className="wrapper1420">
                        <h1>о бренде</h1>
                        {isMobile ?
                                    (<>
                                    <p>
                                        Свитлогорье — волшебное место, где для каждого найдется что-то свое!  
                                        В любую погоду, каждый день, утром или вечером, в самом разном настроении —  
                                        здесь вы найдете удивительно вкусные продукты, которые не оставят равнодушным никого. 
                                        <br/><br/>
                                        Нашу продукцию мы создаем с любовью и заботой о наших покупателях,  
                                        именно поэтому тщательно подходим к процессу ее производства.  
                                        Продукция ТМ «Свитлогорье» производится на высокотехнологичном оборудовании,  
                                        отвечающем современным стандартам качества. Оборудование оснащено многоступенчатыми  
                                        системами фильтрации, что гарантирует высокое качество молочной продукции. 
                                        <br/><br/>
                                        Путешествуйте по Свитлогорью вместе с нами, пробуйте нашу продукцию,  
                                        созданную с любовью и заботой о вас и ваших близких, и обязательно возвращайтесь еще!
                                    </p>
                                    </>)
                                    :
                                    (<>
                                    <p>
                                        Свитлогорье — волшебное место, где для каждого найдется что-то свое!  
                                        <br/>В любую погоду, каждый день, утром или вечером, в самом разном настроении —  
                                        <br/>здесь вы найдете удивительно вкусные продукты, которые не оставят равнодушным никого. 
                                        <br/>
                                        <br/>Нашу продукцию мы создаем с любовью и заботой о наших покупателях,  
                                        <br/>именно поэтому тщательно подходим к процессу ее производства.  
                                        <br/>Продукция ТМ «Свитлогорье» производится на высокотехнологичном оборудовании,  
                                        <br/>отвечающем современным стандартам качества. Оборудование оснащено многоступенчатыми  
                                        <br/>системами фильтрации, что гарантирует высокое качество молочной продукции. 
                                        <br/>
                                        <br/>Путешествуйте по Свитлогорью вместе с нами, пробуйте нашу продукцию,  
                                        <br/>созданную с любовью и заботой о вас и ваших близких, и обязательно возвращайтесь еще!
                                    </p>
                                    </>)
                        }
            
                    </div>
                </div>
                <div className="block7" id="Product">
                    <div className="wrapper1420">
                        <h1>Наши продукты</h1>
                        <SwiperProduct/>
                    </div>
                </div>
                <div className="block8" id="Sales">
                    <div className="wrapper1420">
                        <h1>где купить</h1>
                        <div className="row">
                            <div className="col-4">
                                <a href="https://vml8.adj.st/promocategory/d2c57969-a958-480b-b402-5d00397c8477?showcaseType=MINIMARKET&adj_t=5n3ryif&adj_campaign=partner_russkoe_moloko&adj_adgroup=yandex&adj_creative=syrki_dec" target="_blank">
                                    <img src="images/block8_sale_5.png"/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://fy7t.adj.st/external?service=grocery&href=?item=0f9a887d35d041d9a13fa1bf51403458000100010001&adj_t=hdqfo1z_81lvfqw&adj_fallback=https%3A%2F%2Flavka.yandex.ru%2F&adj_redirect_macos=https%3A%2F%2Flavka.yandex.ru%2F" target="_blank">
                                    <img src="images/block8_sale_1.png"/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://vkusvill.ru/goods/syrok-tvorozhnyy-svitlogore-glazirovannyy-s-tverdym-syrom-palermo-v-molochnom-shokolade-23-55-g-63675.html" target="_blank">
                                    <img src="images/block8_sale_3.png"/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://www.ozon.ru/highlight/svitlogore-1272084/?miniapp=supermarket" target="_blank">
                                    <img src="images/block8_sale_2.png"/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://sbermarket.ru/metro/search?keywords=сырки%20свитлогорье&sid=1" target="_blank">
                                    <img src="images/block8_sale_6.png"/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://www.vprok.ru/product/svitlogore-syrok-tvorojnyy-glazirovannyy-s-tverdym-syrom-palermo-v-molochnom-shokolade-55-g--1216837?searchPhrase=%D1%81%D0%B2%D0%B8%D1%82%D0%BB%D0%BE%D0%B3%D0%BE%D1%80%D1%8C%D0%B5" target="_blank">
                                    <img src="images/block8_sale_4.png"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            )
}

export default Index