import React from 'react';

const NotFound =()=> {
    return (
            <>
                <div className="block-notFound">
                    <div className="wrapper1280">
                        <div className="row">
                            <h1>404 - страница не найдена</h1>
                        </div>
                    </div>
                </div>
            </>
            )
}

export default NotFound