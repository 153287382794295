import React from 'react';

const Mixer = () => {
    return (
            <div id="animation_container">
                <canvas id="canvas"></canvas>
                <div id="dom_overlay_container"></div>
            </div>
            )
}

export default Mixer