import React, { Component, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { NativeSelect, OutlinedInput, MenuItem, Select } from '@mui/material';
import Login from '../modal/login';
import Recovery from '../modal/recovery';
import Message from '../modal/message';
import { AbonentContext } from '../index/content';

function Menu(props) {

    const [isMenuClicked, setIsMenuClicked] = useState(false)
    const [menu_class, setMenu_class] = useState("")
    const isMobile = window.innerWidth <= 1023;
    const menusData = [
        {name: "Замиксуйте свой сырок", href: "/#Mix_product", target: "", id: "", class: "menuLink"},
        {name: "О бренде", href: "/#Brend", target: "", id: "", class: "menuLink"},
        {name: "Наши продукты", href: "/#Product", target: "", id: "", class: "menuLink"},
        {name: "Где купить", href: "/#Sales", target: "", id: "", class: "menuLink"}
    ];

    function updateMenu(e) {
        if (!isMenuClicked) {
            setMenu_class("isOpen");
            document.body.classList.add('body');
        } else {
            setMenu_class("");
            document.body.classList.remove('body');
        }
        setIsMenuClicked(!isMenuClicked);
    }

    const rows = menusData.map((data, index) => {
        return (
                <li key={index}>
                    <Link to={data.href} target={data.target} id={data.id} className={data.class} onClick={updateMenu} reloadDocument>{data.name}<img src={data.img}/></Link>
                </li>
                        );
            });

            if (isMobile) {
                return (
                                <>
                                <div className="menu">
                                    <div className="block-logo">
                                    <a href="https://svitlogorie.ru/product/tvorozhnye-syrki-i-palochki/" target="_blank"><img src="/images/logo.png"/></a>
                                    </div>
                                    <nav className="navigation row">
                                        <div id="menuToggle" className={menu_class}>
                                            <div className={menu_class} onClick={updateMenu}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            <ul id="menu-ul">
                                                {rows}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                </>
                        );
            } else {
                return (
                                <>
                                <div className="block-menu">
                                    <div className="block-bg-menu"></div>
                                    <div className="block-logo">
                                        <a href="https://svitlogorie.ru/product/tvorozhnye-syrki-i-palochki/" target="_blank"><img src="/images/logo.png"/></a>
                                    </div>
                                    <nav className="navigation">
                                        <ul id="menu-ul">
                                            {rows}
                                        </ul>
                                    </nav>
                                </div>
                                </>
                        );
            }
        }

        export default Menu


