import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AbonentContext } from '../index/content';


const Footer = () => {
    const {language} = useContext(AbonentContext);

    return (
            <div className="footer">
                
            </div>
            )
}

export default Footer
