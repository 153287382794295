import React, { Component, useEffect, useState, createContext } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Index from './index';
import Lk from './lk';
import Menu from '../menu/menu';
import NotFound from './notFound';
import Footer from '../footer/footer';

export const AbonentContext = createContext(null);

function Content(props) {

    return(
            <AbonentContext.Provider value={{}}>
                <Menu />
                <Routes>
                    <Route path="/" element={ < Index / > }/>
                    <Route path="/index" element={ < Index / > }/>
                    <Route path="*" element={ < NotFound / > }/>
                </Routes>
                <Footer />
            </AbonentContext.Provider>
    )
}

export default Content