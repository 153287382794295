import React, { Component, useState, useEffect, useContext } from 'react'
import { Button } from "@mui/material";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import { AbonentContext } from '../index/content';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Swipe = (props) => {
    const [product, setProduct] = useState([
        {'img':'block7_product_1.png', 'title':'Сырок “Свитлогорье” творожный <br/>глазированный с ванилью в молочном <br/>шоколаде.', 'text':'<br/>В этом сырке натуральный творог оттеняется нежным ароматом ванили, а густой слой молочного шоколада делает вкус по-настоящему законченным.<br/><br/>Чистый состав, непревзойдённый вкус, увеличенный вес сырка — вам точно понравится!'},
        {'img':'block7_product_2.png', 'title':'Сырок “Свитлогорье” творожный <br/>глазированный с варёной сгущёнкой <br/>в молочном шоколаде', 'text':'<br/>Классический вкус натурального творога с начинкой из любимого с детства вареного сгущенного молока — что может быть лучше? Завершает вкусовую композицию молочный бельгийский шоколад.<br/><br/>Чистый состав, непревзойдённый вкус, увеличенный вес сырка — вам точно понравится!'},
        {'img':'block7_product_3.png', 'title':'Сырок “Свитлогорье” творожный <br/>глазированный с кокосом в молочном <br/>шоколаде', 'text':'<br/>Когда классический вкус натурального творогас экстрактом ванили встречается с белоснежной кокосовой стружкой и бельгийским молочным шоколадом, вы попадаете в атмосферу тропического наслаждения!<br/><br/>Чистый состав, непревзойдённый вкус, увеличенный вес сырка — вам точно понравится!'},
        {'img':'block7_product_4.png', 'title':'Сырок “Свитлогорье” творожный <br/>глазированный с твердым сыром Palermo <br/>в молочном шоколаде', 'text':'<br/>Вкус натурального творога с кусочками твердого сыра приятно оттеняется сладостью молочного шоколада: этот сырок создан для настоящих гурманов!<br/><br/>Чистый состав, непревзойдённый вкус, увеличенный вес сырка — вам точно понравится'},
        {'img':'block7_product_5.png', 'title':'Сырок “Свитлогорье” творожный глазированный с твёрдым <br/>сыром Palermo, с инжиром в молочном <br/>шоколаде', 'text':'<br/>Этот сырок сочетает вкусы, которые по достоинству оценят настоящие гурманы. Нежный натуральный творог, бельгийский молочный шоколад, а внутри — дуэт твердого сыра “Палермо” и экзотического инжира<br/><br/>Чистый состав и непревзойдённый вкус — вам точно понравится!'}
    ]);

    const stepProduct = product.map((data, index) => {
        return(
                <SwiperSlide key={index}>
                    <div className="swiper-slide-img">
                        <img src={'images/'+data.img}/>
                    </div>
                    <div className="swiper-slide-text">
                        <h1 dangerouslySetInnerHTML={{__html:data.title}}></h1>
                        <p dangerouslySetInnerHTML={{__html:data.text}}></p>
                    </div>
                </SwiperSlide>
                )
    });

    return (
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={1}
                slidesPerGroup={1}
                initialSlide={0}
                loop={false}
                navigation
                pagination
                onSwiper={(swiper) => console.log()}
                onSlideChange={() => console.log()}
                className="show-swiper"
                >
                {stepProduct}
            </Swiper>
            );
};

export default Swipe